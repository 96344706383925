import { render, staticRenderFns } from "./reserveCardDetail.vue?vue&type=template&id=25d308d7&scoped=true&"
import script from "./reserveCardDetail.vue?vue&type=script&lang=ts&"
export * from "./reserveCardDetail.vue?vue&type=script&lang=ts&"
import style0 from "./reserveCardDetail.vue?vue&type=style&index=0&id=25d308d7&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25d308d7",
  null
  
)

export default component.exports