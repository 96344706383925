
























































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { apiPhysicalVouchersCouponBackDetail } from "@/api/card/physical_vouchers";
import index from "@/template-editor/client/components/add-cooperationer/index.vue";
@Component({})
export default class reserveCardDetail extends Vue {
    id = this.$route.query.id;
    detail = {
        sn: "",
        type: 1,
        number: 0,
        printing_method: 1,
        coupon_password_email: "",
        keys_phone: "",
        email_topic: "",
        email_content: "",
        material: 1,
        size: 1,
        effect: 1,
        monovalent: 0,
        amount: 0,
        total_cost: 0,
        other_notes: "",
        is_urgent: 1,
        is_expiration_date: 1,
        contact: "",
        contact_number: "",
        mailing_address: "",
        shipping_methods: 1,
        source_files: "",
        preview_files: "",
    };

    tableData: any[] = [];

    async created() {
        await this.getDetail();
    }

    downFile(name: string, url: string) {
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.target = "_blank";
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    getDetail() {
        apiPhysicalVouchersCouponBackDetail({
            id: this.id,
        }).then((res) => {
            this.detail = res;

            res.dls_product_profiles_auditing_log_list.forEach(
                (element: any, index: number) => {
                    if (element.end_time === "") {
                        res.dls_product_profiles_auditing_log_list[
                            index
                        ].delayedTime = "";
                    } else {
                        let start_time =
                            new Date(element.start_time).getTime() / 1000;
                        let end_time =
                            new Date(element.end_time).getTime() / 1000;
                        res.dls_product_profiles_auditing_log_list[
                            index
                        ].delayedTime =
                            Math.ceil((end_time - start_time) / 60) + "分钟";
                    }
                }
            );

            this.tableData = res.dls_product_profiles_auditing_log_list;
        });
    }
}
